import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MusicVideo } from "@mui/icons-material";
import ReactAudioPlayer from "react-audio-player";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const CallRecordings = ({ updateCallListeningTime }) => {
  const { evalID } = useParams();
  const [currentPlaybackTimes, setCurrentPlaybackTimes] = useState({});
  const [recordings, setRecordings] = useState([]);

  const getCallRecordings = useQuery([`Recordings${evalID}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`,
    );
  });

  const Recordings = getCallRecordings.data?.data?.data;

  const postRecording = useMutation({
    mutationFn: (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`,
        data,
      );
    },
    onSuccess: () => {
      getCallRecordings.refetch();
    },
  });

  const handleFileChange = (event) => {
    const newRecordings = Array.from(event.target.files).filter((file) =>
      file.type.startsWith("audio/"),
    );
    setRecordings(newRecordings);
  };

  const handleSave = () => {
    const formData = new FormData();
    recordings.forEach((file) => {
      formData.append("file", file);
    });
    postRecording.mutate(formData);
    setRecordings([]);
  };

  const updateListeningTime = (recordingId, increment) => {
    setCurrentPlaybackTimes((prev) => {
      const newTime = (prev[recordingId] || 0) + increment;

      return {
        ...prev,
        [recordingId]: newTime,
      };
    });
  };

  useEffect(() => {
    const totalTime = Object.values(currentPlaybackTimes).reduce(
      (sum, time) => sum + time,
      0,
    );
    updateCallListeningTime(totalTime);
  }, [currentPlaybackTimes, updateCallListeningTime]);

  if (getCallRecordings.isLoading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ my: 2 }}>
      <Box>
        <input
          accept=".mp3,.wav"
          id="contained-button-file"
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {recordings.length === 0 ? (
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              sx={{ px: 5, py: 2, borderStyle: "dashed", mb: 2 }}
              startIcon={<MusicVideo />}
              fullWidth
              component="span"
            >
              Upload New Call Recording
            </Button>
          </label>
        ) : (
          <Box
            sx={{
              p: 2,
              borderColor: "secondary.main",
              borderWidth: "2px",
              borderStyle: "dashed",
            }}
          >
            <Typography variant="span">
              {recordings.map((file) => file.name)}
            </Typography>
            <Button
              sx={{ px: 5, py: 2, m: 1 }}
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
      {Recordings?.length > 0 && (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Uploaded Recordings
            </Typography>
            <Stack spacing={2}>
              {Recordings.map((recording, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ReactAudioPlayer
                    style={{ width: "800px" }}
                    src={`${process.env.REACT_APP_API_URL}/QAInbound/admin/${recording.id}/recording/download`}
                    controls
                    onPlay={() => {
                      const interval = setInterval(() => {
                        updateListeningTime(recording.storage_id, 1);
                      }, 1000);
                      recording.intervalId = interval;
                    }}
                    onPause={() => {
                      clearInterval(recording.intervalId);
                    }}
                    onEnded={() => {
                      clearInterval(recording.intervalId);
                    }}
                  />
                  <Typography>{recording.name}</Typography>
                </Box>
              ))}
            </Stack>
          </CardContent>
        </Card>
      )}

      <AlertPopup
        open={postRecording.isSuccess}
        message="Recording added Successfully"
        severity="success"
      />
      <AlertPopup
        open={postRecording.isError}
        message="Error adding recording"
        severity="error"
      />
    </Box>
  );
};

export default CallRecordings;
